import React from 'react';
import {QuoteReferenceText, QuoteWrapper, TextWrapper} from "./quote.style";
import quoteImage from '../../images/suchatchavee-sub-banner-pic.webp';


const Quote = () => {
	return (
		<QuoteWrapper>
			<img src={quoteImage} alt=""/>
			<TextWrapper>
				<p>" We aim to drive society to the next level by using technology and innovation, and also further promote Thailand 4.0. The digital world will be built on campus networks, with the future defined by connections between all this. "</p>
				<QuoteReferenceText>
					<strong>Prof.Dr.Suchatvee Suwansawat</strong>
					<p>
						President, King Mongkut's Institute of
						Technology Ladkrabang, Thailand
					</p>
				</QuoteReferenceText>
			</TextWrapper>
	 	</QuoteWrapper>
	);
};

export default Quote;
