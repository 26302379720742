import styled from 'styled-components';
import img from './../../images/hero.webp';

export const BannerWrapper = styled.section`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-image: url(${img});
	width: 100%;
	height: fit-content;
	background-size: cover;
	background-color: transparent;
	padding: 3rem 0;
`;

export const TextWrapper = styled.div`
	padding: 1rem 0.5rem 1rem;	
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	h1 {
		font-size: 1.5rem;
	}
	h2 {
		font-size: 1.2rem;
	}
`;
