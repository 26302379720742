import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from '../components/home/banner.component'
import Feature from "../components/home/feature.component";
import Quote from "../components/home/quote.component";


const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <Banner/>
    <Feature/>
    <Quote/>
  </Layout>
);

export default IndexPage
