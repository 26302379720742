import styled from 'styled-components';

export const QuoteWrapper = styled.div`
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	
	img {
		width: 60%;
	}
	
	> div > p {
		text-align: justify;
	}
	
	@media only screen and (max-width: 480px) {
		flex-direction: column;
		align-items: center;
		img {
			width: 90%;
		}
	}
`;

export const TextWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 1.5rem;
`;

export const QuoteReferenceText = styled.div`
	font-size: 0.8rem;
	line-height: 1rem;
`;
