import React from "react";
import {BannerWrapper, TextWrapper} from "./banner.style";

const Banner = () => {
	return (
		<BannerWrapper>
			<TextWrapper>
				<h1>KMITL <br/>Sustainable Campus</h1>
				<p>
					King Mongkut's Institute of Technology Ladkrabang. <br />
					Eco-friendly and healthy workplace.
				</p>
			</TextWrapper>
		</BannerWrapper>
	);
};

export default Banner;
