import styled from  'styled-components';

export const FeatureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 2rem 0;
	
	@media only screen and (max-width: 480px) {
		flex-direction: column;
		align-items: center;
	}
`;

export const FeatureCard = styled.div`	
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 30%;
	p {
		text-indent: 2rem;
		text-align: justify;
	}
	h1 {
		font-size: 1.4rem;	
	}	
	img {
		width: 90%;
	}
	@media only screen and (max-width: 480px) {
		flex-direction: column;
		width: 80%;
		img {
			width: 60%;
		}
	}
`;
