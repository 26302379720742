import React from 'react';
import {FeatureCard, FeatureWrapper} from "./feature.style";
import sustainableImage from '../../images/sustainable-education.webp';
import greenEnvironments from '../../images/green-environment.webp';
import growth from '../../images/growth.webp';

const Feature = () => {
	return (
		<FeatureWrapper>
			<FeatureCard>
				<h1>
					Sustainable Education
				</h1>
				<img src={`${sustainableImage}`} alt=""/>
				<p>Provides world-class education and research specialised in science and technology to develop global community.</p>
			</FeatureCard>
			<FeatureCard>
				<h1>
					Green Environment
				</h1>
				<div/>
				<img src={`${greenEnvironments}`} alt=""/>
				<p>
					Solves drastic environment problem and proposes eco-friendly method or innovation to preserve natural resources.
				</p>
			</FeatureCard>
			<FeatureCard>
				<h1>
					Growth
				</h1>
				<img src={`${growth}`} alt=""/>
				<p>
					Initiates breakthrough innovation to tackle with the fast-changing world and promote nations economic growth.
				</p>
			</FeatureCard>
		</FeatureWrapper>
	)
};

export default Feature;
